import { Form, Button, Table, Modal, Row, Col } from 'react-bootstrap'

import genericRequestWithSpinner from "../utilities/genericRequestWithSpinner"


const ModalStaticData = ( { selectedStaticData, action, setAction, selectedEntry, setSelectedEntry, entries, setEntries, setSpinner } ) => {
    const handleClose = () => { setSelectedEntry({}); setAction(''); }

    const onChange = (event) => {
        console.log('onChange')
        let cloneEntryToUpdate = { ...selectedEntry };
        cloneEntryToUpdate[event.target.id] = event.target.value
        setSelectedEntry(cloneEntryToUpdate)
    }

    const onChangeCheckBox = (event) => {
        console.log('onChangeCheckBox')
        let cloneEntryToUpdate = { ...selectedEntry };
        cloneEntryToUpdate[event.target.id] = !cloneEntryToUpdate[event.target.id]
        setSelectedEntry(cloneEntryToUpdate)
    }

    const handleStaticDataEntry = () => {
        console.log('handleStaticDataEntry ' + action)
        const requestData = {
            'dataType': selectedStaticData,
            'action': action,
            'data': selectedEntry
        }
        console.log(requestData)
        genericRequestWithSpinner('StaticData', requestData, setEntries, setSpinner)
        setSelectedEntry({})
        setAction('')
        setSpinner(true)
    }

    return (
        <div>
            <Modal show="true" size="lg" centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {action} Entry
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Row className='align-middle'>
                        <Table size="sm" className='rounded-4 overflow-hidden mb-0'>
                            <thead className="table-primary">
                                <tr>
                                    {entries['columns'].map((column, index) => (
                                        <th key={index}>{column['name']}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr key="0">
                                    {entries['columns'].map((column, index) => (
                                        column['type'] === "BOOLEAN" ? (
                                            <td key={index}>
                                                <Form.Group controlId={column['name']}>
                                                    <Form.Check 
                                                        checked={Object.keys(selectedEntry).length !== 0 ? (selectedEntry[column['name']]) : false}
                                                        disabled={action === 'Delete' ? (true) : false}
                                                        onChange={onChangeCheckBox}/>
                                                </Form.Group>
                                            </td>
                                        ) : (
                                            <td key={index}>
                                                <Form.Group controlId={column['name']}>
                                                    <Form.Control size="sm" 
                                                        defaultValue={Object.keys(selectedEntry).length !== 0 ? (selectedEntry[column['name']]) : null} 
                                                        disabled={action === 'Delete' || column['primary_key'] ? (true) : false}
                                                        onChange={onChange}/>
                                                </Form.Group>
                                            </td>
                                        )
                                    ))}
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col xs={9}></Col>
                        <Col xs={3} className='align-middle text-center mb-3'>
                            <Button variant="primary" onClick={handleStaticDataEntry}>
                                {action}
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalStaticData