import { Nav } from 'react-bootstrap'


const StaticDataMenu = ( { selectedStaticData, handleSelect } ) => {
    

    return (
        <Nav variant="pills" activeKey={selectedStaticData} className="flex-column" onSelect={handleSelect}>
            <Nav.Item>
                <Nav.Link eventKey="ContractDefinition">Contracts Definitions</Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
                <Nav.Link eventKey="AccountDetails">Accounts Details</Nav.Link>
            </Nav.Item> */}
            <Nav.Item>
                <Nav.Link eventKey="Portfolios">Portfolios</Nav.Link>
            </Nav.Item>
        </Nav>
    )
}

export default StaticDataMenu