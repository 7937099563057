import { useState } from "react"
import { Modal, Table, Card, Form, Button, Spinner } from 'react-bootstrap'

import ModalStaticData from "./ModalStaticData"


const StaticDataDisplay = ( { selectedStaticData, entries, setEntries, spinner, setSpinner } ) => {
    const [selectedEntry, setSelectedEntry] = useState({})
    const [action, setAction] = useState('')
    
    const generateBlankEntry = () => {
        var blank = {}
        
        for (const column of entries['columns']) {
            if (column['type'] === "BOOLEAN") {
                blank[column['name']] = false
            } else {
                blank[column['name']] = ''
            }
        }

        console.log('generateBlankEntry')
        console.log(blank)
        return blank
    }

    return (
        <>
            <Card >
            <Card.Header>{selectedStaticData}</Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <Table size="sm" hover>
                        {Object.keys(entries['columns']).length !== 0 ? (
                            <>
                                <thead>
                                    <tr>
                                        {entries['columns'].map((column, index) => (
                                            <th key={index}>{column['name']}</th>
                                        ))}
                                        <th style={{width: "5px"}}></th>
                                        <th style={{width: "5px"}}>
                                            <Button variant="secondary" size="ssm" onClick={() => {setSelectedEntry(generateBlankEntry()); setAction('New')}}><i className="bi bi-plus-square icon-60"></i></Button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {entries['lines'].map((staticData, indexTR) => (
                                        <tr key={indexTR}>
                                            {entries['columns'].map((column, indexTD) => (
                                                column['type'] === "BOOLEAN" ? (
                                                    <td key={indexTD}><Form.Check readOnly checked={staticData[column['name']]}/></td>
                                                ) : (
                                                    <td key={indexTD}>{staticData[column['name']]}</td>
                                                )
                                            ))}
                                            <td key="Button">
                                                <Button variant="secondary" size="ssm" onClick={() => {setSelectedEntry(entries['lines'][indexTR]); setAction('Update')}}><i className="bi bi-pen icon-60"></i></Button>
                                            </td>
                                            <td key="CloseButton">
                                                <Button variant="secondary" size="ssm" onClick={() => {setSelectedEntry(entries['lines'][indexTR]); setAction('Delete')}}><i className="bi bi-x-circle icon-60"></i></Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </>
                        ) : null}
                    </Table>
                </Card.Text>
            </Card.Body>
            {action !== '' ? (
                <ModalStaticData selectedStaticData={selectedStaticData} action={action} setAction={setAction} selectedEntry={selectedEntry} setSelectedEntry={setSelectedEntry} 
                                entries={entries} setEntries={setEntries} setSpinner={setSpinner}></ModalStaticData>
            ) : null}
            {spinner ? (
                <Modal show="true" size="sm" centered>
                    <Modal.Body>
                        <Spinner animation="border" variant="primary" size="lg"/>
                    </Modal.Body>
                </Modal>
            ) : null}
        </Card>
        </>
    )
}

export default StaticDataDisplay